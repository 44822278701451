import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import * as BookingSummaryStore from '@/vue/stores/Booking/BookingStore';
import * as BookingModule from './BookingModule';
import * as BookingInsuranceModule from './stores/BookingInsurance/BookingInsuranceModule';
import * as MyBookingsStore from './stores/MyBookings/MyBookingsStore';

export const {
  getUpgradeInsuranceInfo,
  fetchBookingRules,
  upgradeInsuranceInfo,
  bookingRulesData,
  fetchMyActiveBookings,
  fetchMyRequestBookings,
  putCancelBooking,
  deletePreBooking,
  getBookingCancelFees,
  getMyActiveBookings,
  getMyRequestBookings,
  getMyActiveBookingByUseCase,
  getMyActiveBookingsAndPrebookings,
  getActiveBookingByUuid,
  hasActiveBookings,
  hasActivePreBookings,
  hasActiveInterventions,
  getMyActiveBookingsCardinal,
  getMyRequestBookingsCardinal,
  getMyActiveInterventionsCardinal,
  getMyHistoryBookings,
  fetchHistoryBookings,
  getMyHistoryInterventions,
  getMyHistoryBookingsPagination,
  setReservation,
  setSummary,
  startBooking,
  createBookingRequestData,
  reservation,
  isBooking,
  bookingVehicle,
  isOutsideWorkingHours,
  bookingLocation,
  isBookingLocationOpen,
  isPrebooking,
  bookingDriver,
  driverBookingOnBehalfProfileUuid,
  bookingOperatorUuid,
  isBookingOnBehalf,
  reservationOperator,
  bookingSummary,
  bookingSummaryTotalPrice,
  bookingInsurance,
  bookedTime,
  driverProfileUuid,
  bookingUserUuid,
  bookingLocationUuid,
  bookingDestinationLocationUuid,
  bookingVehicleCategory,
  bookingVehicleCategoryUuid,
  bookingPeriods,
} = {
  ...mapActions(BookingModule.NAMESPACE, {
    fetchBookingRules: BookingModule.ACTIONS.fetchBookingRules,
  }),
  ...mapActions(`${BookingModule.NAMESPACE}/${BookingInsuranceModule.NAMESPACE}`, [
    BookingInsuranceModule.ACTIONS.getUpgradeInsuranceInfo,
  ]),
  ...mapActions(MyBookingsStore.NAMESPACE, {
    fetchMyActiveBookings: MyBookingsStore.ACTIONS.fetchMyActiveBookings,
    fetchMyRequestBookings: MyBookingsStore.ACTIONS.fetchMyRequestBookings,
    fetchHistoryBookings: MyBookingsStore.ACTIONS.fetchHistoryBookings,
    putCancelBooking: MyBookingsStore.ACTIONS.putCancelBooking,
    deletePreBooking: MyBookingsStore.ACTIONS.deletePreBooking,
    getBookingCancelFees: MyBookingsStore.ACTIONS.getBookingCancelFees,
  }),

  ...mapGetters(BookingModule.NAMESPACE, {
    bookingRulesData: BookingModule.GETTERS.bookingRulesData,
    isBookingBetweenRules: BookingModule.GETTERS.isBookingBetweenRules,
  }),

  ...mapGetters(MyBookingsStore.NAMESPACE, {
    getMyActiveBookings: MyBookingsStore.GETTERS.getMyActiveBookings,
    getMyRequestBookings: MyBookingsStore.GETTERS.getMyRequestBookings,
    getMyActiveBookingByUseCase: MyBookingsStore.GETTERS.getMyActiveBookingByUseCase,
    getMyActiveBookingsAndPrebookings: MyBookingsStore.GETTERS.getMyActiveBookingsAndPrebookings,
    getActiveBookingByUuid: MyBookingsStore.GETTERS.getActiveBookingByUuid,
    hasActiveBookings: MyBookingsStore.GETTERS.hasActiveBookings,
    hasActivePreBookings: MyBookingsStore.GETTERS.hasActivePreBookings,
    hasActiveInterventions: MyBookingsStore.GETTERS.hasActiveInterventions,
    getMyActiveBookingsCardinal: MyBookingsStore.GETTERS.getMyActiveBookingsCardinal,
    getMyRequestBookingsCardinal: MyBookingsStore.GETTERS.getMyRequestBookingsCardinal,
    getMyActiveInterventionsCardinal: MyBookingsStore.GETTERS.getMyActiveInterventionsCardinal,
    getMyHistoryBookings: MyBookingsStore.GETTERS.getMyHistoryBookings,
    getMyHistoryInterventions: MyBookingsStore.GETTERS.getMyHistoryInterventions,
    getMyHistoryBookingsPagination: MyBookingsStore.GETTERS.getMyHistoryBookingsPagination,
  }),

  ...mapState(`${BookingModule.NAMESPACE}/${BookingInsuranceModule.NAMESPACE}`, {
    upgradeInsuranceInfo: state => state.upgradeInsuranceInfo.data,
  }),

  ...mapMutations(BookingSummaryStore.nameSpace, {
    setReservation: BookingSummaryStore.MUTATIONS.SET_RESERVATION,
    setSummary: BookingSummaryStore.MUTATIONS.SET_SUMMARY,
    startBooking: BookingSummaryStore.MUTATIONS.START_BOOKING,
  }),

  ...mapGetters(BookingSummaryStore.nameSpace,
    [
      'bookedTime',
      'bookingDriver',
      'bookingInsurance',
      'bookingLocation',
      'bookingSummary',
      'bookingSummaryTotalPrice',
      'bookingVehicle',
      'createBookingRequestData',
      'driverBookingOnBehalfProfileUuid',
      'isBooking',
      'isBookingLocationOpen',
      'isBookingOnBehalf',
      'isPrebooking',
      'reservation',
      'reservationOperator',
      'isOutsideWorkingHours',
      'bookingOperatorUuid',
      'driverProfileUuid',
      'bookingUserUuid',
      'bookingLocationUuid',
      'bookingDestinationLocationUuid',
      'bookingVehicleCategory',
      'bookingVehicleCategoryUuid',
      'bookingPeriods',
    ]),
};
