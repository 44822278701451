import reduce from 'lodash/reduce';
import camelCase from 'lodash/camelCase';

import { ldClient } from '@emobg/access-utils';
import config from '@/config';
import languageService from '@/services/Language';

/**
 * LaunchDarkly Payload object
 * @typedef {object} LdPayload
 * @property key {string} - Key to identify the user
 * @property custom {CustomDataLdPayload} - Custom data used to select users on the dashboard
 */

/**
 * Custom data for the payload to identify a user in LauchDarkly
 * @typedef {object} CustomDataLdPayload
 * @property app_version {string} - WebApp version
 * @property company_name {string}
 * @property company_uuid {string}
 * @property country {string}
 * @property default_city_uuid {string}
 * @property device_os {string}
 * @property gender {string}
 * @property locale_code {string}
 * @property novice {string}
 * @property operator_name {string}
 * @property operator_uuid {string}
 * @property status {string}
 */

const LAUNCHDARKLY_API_KEY = config.data.launchDarklyApiKey;

let launchDarklyInstance = null;
let featureFlags = null;

export const toCamelCaseFlags = flags => reduce(flags, (result, value, flag) => ({
  ...result,
  [camelCase(flag)]: value,
}), {});

export const createLaunchDarklyInstance = user => {
  if (!launchDarklyInstance) {
    launchDarklyInstance = ldClient(LAUNCHDARKLY_API_KEY, user);
  }

  return launchDarklyInstance;
};

export const destroyLaunchDarklyInstance = () => {
  launchDarklyInstance = undefined;
  featureFlags = undefined;
};

export const getFlags = instance => {
  if (featureFlags) {
    return featureFlags;
  }

  return new Promise(resolve => {
    instance.on('ready', () => {
      featureFlags = instance.allFlags();
      resolve(featureFlags);
    });
  });
};

/**
 * Creates a LaunchDarkly payload from a user details endpoint
 * @param user {object} - Response from /from-idp endpoint
 * @param operator
 * @return {LdPayload}
 */
export const getInstancePayload = ({ user = {}, operator = {} } = {}) => {
  const language = languageService.getLanguage();
  const regionLocale = languageService.getLanguageCodeFromLocale(language);

  const {
    uuid: key = '',
    country = '',
    gender = '',
    novice = '',
    status = '',
    company = { uuid: '', commercial_name: '' },
    locale_code: localeCode = regionLocale,
    default_city_uuid: defaultCityUuid = '',
  } = user;

  const {
    uuid: operatorUuid = '',
    name: operatorName = '',
  } = operator;

  return {
    key,
    custom: {
      app_version: config.data.appVersion,
      company_name: company.commercial_name,
      company_uuid: company.uuid,
      country,
      default_city_uuid: defaultCityUuid,
      device_os: 'other',
      gender,
      locale_code: localeCode,
      novice,
      operator_name: operatorName,
      operator_uuid: operatorUuid,
      status,
    },
  };
};
