import { mapActions, mapGetters, mapState } from 'vuex';
import get from 'lodash/get';
import { nameSpace as CSOperatorNameSpace } from '@/vue/stores/CSOperator/CSOperatorStore';

export const {
  addCSOperatorInCollection,
  fetchCSOperator,
  findCSOperatorByUUID,
  findOperatorBy,
  getCSOperatorCollection,
  getCurrentCSOperator,
  getOperatorMileageSymbol,
  getVisitedCsOperator,
  operatorHasOneWay,
  operatorPhone,
  resetCSOperatorStore,
  setCSOperatorCollection,
  setCurrentCSOperator,
  setVisitedCsOperator,
  hasRestrictiveBadge,
  hasLogBook,
  getCurrentOperatorServicePhone,
  getCurrentOperatorServiceEmail,
  getCurrentOperatorCurrency,
  getVisitedCsOperatorHelpLink,
} = {
  ...mapGetters(CSOperatorNameSpace, [
    'findCSOperatorByUUID',
    'findOperatorBy',
    'getCSOperatorCollection',
    'getCurrentCSOperator',
    'getOperatorMileageSymbol',
    'getVisitedCsOperator',
    'operatorHasOneWay',
    'hasRestrictiveBadge',
    'hasLogBook',
    'getCurrentOperatorServicePhone',
    'getCurrentOperatorServiceEmail',
    'getCurrentOperatorCurrency',
    'getVisitedCsOperatorHelpLink',
  ]),

  ...mapActions(CSOperatorNameSpace, [
    'addCSOperatorInCollection',
    'fetchCSOperator',
    'resetCSOperatorStore',
    'setCSOperatorCollection',
    'setCurrentCSOperator',
    'setVisitedCsOperator',
  ]),

  ...mapState(CSOperatorNameSpace, {
    operatorPhone: state => get(state, 'currentOperatorConfig.customer_service_phone'),
  }),
};
