import get from 'lodash/get';
import filter from 'lodash/filter';
import concat from 'lodash/concat';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import size from 'lodash/size';

import { compactArray, createCustomStore, withScopes } from '@emobg/web-utils';
import { external } from '@emobg/web-api-client';

import { isIntervention, isRegular } from '@/helpers/booking/bookingHelpers';
import { BOOKING_TYPES } from '@/constants/bookingTypes';

export const SCOPES = {
  myActiveBookings: 'myActiveBookings',
  myRequestBookings: 'myRequestBookings',
  myHistoryBookings: 'myHistoryBookings',
  bookingStatus: 'bookingStatus',
};

export const GETTERS = {
  getAllMyActiveBookings: 'getAllMyActiveBookings',
  getMyActiveBookings: 'getMyActiveBookings',
  getMyRequestBookings: 'getMyRequestBookings',
  getMyActiveInterventions: 'getMyActiveInterventions',
  getMyHistoryInterventions: 'getMyHistoryInterventions',
  getMyActiveBookingByUseCase: 'getMyActiveBookingByUseCase',
  getMyActiveBookingsAndPrebookings: 'getMyActiveBookingsAndPrebookings',
  getActiveBookingByUuid: 'getActiveBookingByUuid',
  getMyActiveBookingsCardinal: 'getMyActiveBookingsCardinal',
  hasActiveBookings: 'hasActiveBookings',
  getMyActiveInterventionsCardinal: 'getMyActiveInterventionsCardinal',
  hasActiveInterventions: 'hasActiveInterventions',
  getMyRequestBookingsCardinal: 'getMyRequestBookingsCardinal',
  hasActivePreBookings: 'hasActivePreBookings',
  getMyHistoryBookings: 'getMyHistoryBookings',
  getMyHistoryBookingsPagination: 'getMyHistoryBookingsPagination',
};

export const ACTIONS = {
  fetchMyActiveBookings: 'fetchMyActiveBookings',
  fetchMyRequestBookings: 'fetchMyRequestBookings',
  fetchHistoryBookings: 'fetchHistoryBookings',
  deletePreBooking: 'deletePreBooking',
  putCancelBooking: 'putCancelBooking',
  getBookingCancelFees: 'getBookingCancelFees',
};

export const NAMESPACE = 'MyBookings';

export const store = createCustomStore(({ runAsyncFlow }) => withScopes(SCOPES, (
  {
    getters: {
      [GETTERS.getMyHistoryBookings](state) {
        return get(state, SCOPES.myHistoryBookings) || [];
      },
      [GETTERS.getAllMyActiveBookings](state) {
        return get(state, `${SCOPES.myActiveBookings}.data.data`) || [];
      },
      [GETTERS.getMyRequestBookings](state) {
        return get(state, `${SCOPES.myRequestBookings}.data.data.items`) || [];
      },
      [GETTERS.getMyActiveBookings](_, getters) {
        return filter(
          getters.getAllMyActiveBookings,
          booking => isRegular(get(booking, 'cs_booking_use_case.booking_type')),
        );
      },
      [GETTERS.getMyActiveInterventions](_, getters) {
        return filter(
          getters.getAllMyActiveBookings,
          booking => isIntervention(get(booking, 'cs_booking_use_case.booking_type')),
        );
      },
      [GETTERS.getMyHistoryInterventions](_, getters) {
        return filter(
          get(getters.getMyHistoryBookings, 'data.data'),
          booking => isIntervention(get(booking, 'cs_booking_use_case.booking_type')),
        );
      },
      [GETTERS.getMyHistoryBookingsPagination](state) {
        return get(state, `${SCOPES.myHistoryBookings}.data.pagination`) || [];
      },
      [GETTERS.getMyActiveBookingsAndPrebookings](_, getters) {
        return compactArray(concat(getters.getMyActiveBookings, getters.getMyRequestBookings));
      },
      [GETTERS.getMyActiveBookingsCardinal](_, getters) {
        return size(getters.getMyActiveBookingsAndPrebookings);
      },
      [GETTERS.hasActiveBookings](_, getters) {
        return getters.getMyActiveBookingsCardinal > 0;
      },
      [GETTERS.getMyRequestBookingsCardinal](_, getters) {
        return size(getters.getMyRequestBookings);
      },
      [GETTERS.hasActivePreBookings](_, getters) {
        return getters.getMyRequestBookingsCardinal > 0;
      },
      [GETTERS.getMyActiveInterventionsCardinal](_, getters) {
        return size(getters.getMyActiveInterventions);
      },
      [GETTERS.hasActiveInterventions](_, getters) {
        return getters.getMyActiveInterventionsCardinal > 0;
      },
      [GETTERS.getMyActiveBookingByUseCase](_, getters) {
        return bookingTypes => (isEqual(bookingTypes, [BOOKING_TYPES.intervention])
          ? getters.getMyActiveInterventions
          : getters.getMyActiveBookingsAndPrebookings);
      },
      [GETTERS.getActiveBookingByUuid](_, getters) {
        return uuid => find(getters.getMyActiveBookingsAndPrebookings, { uuid });
      },
    },
    actions: {
      async [ACTIONS.fetchMyActiveBookings]({ commit }, { bookingParams }) {
        await runAsyncFlow(commit, {
          request: external.booking.getBookings,
          params: [bookingParams, 'v4'],
          scope: SCOPES.myActiveBookings,
        });
      },

      async [ACTIONS.fetchMyRequestBookings]({ commit }, { profileUuid }) {
        const query = {
          items_number: 100,
          page_number: 1,
        };
        await runAsyncFlow(commit, {
          request: external.preBooking.getPreBookingList,
          params: [profileUuid, query],
          scope: SCOPES.myRequestBookings,
        });
      },

      async [ACTIONS.fetchHistoryBookings]({ commit }, { payload }) {
        const bookingParams = {
          history: 1,
          ...payload,
        };
        await runAsyncFlow(commit, {
          request: external.booking.getBookings,
          params: [bookingParams, 'v4'],
          scope: SCOPES.myHistoryBookings,
        });
      },
      [ACTIONS.deletePreBooking]({ commit }, { preBookingUuid }) {
        return runAsyncFlow(commit, {
          request: external.preBooking.deletePreBooking,
          params: [preBookingUuid],
          scope: SCOPES.bookingStatus,
        });
      },
      [ACTIONS.putCancelBooking]({ commit }, { bookingUuid, data, version = 'v2' }) {
        return runAsyncFlow(commit, {
          request: external.booking.putCancelBooking,
          params: [bookingUuid, data, version],
          scope: SCOPES.bookingStatus,
        });
      },
      [ACTIONS.getBookingCancelFees]({ commit }, { bookingUuid }) {
        return runAsyncFlow(commit, {
          request: external.booking.getBookingCancelFees,
          params: [bookingUuid],
        });
      },
    },
  }
)));
