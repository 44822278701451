import cfg from '@/config';

const SNIPPET_KEY = cfg.data.siftScienceSnippetKey;

export class SiftScienceSnippet {
  constructor() {
    this.userUUID = null;
  }

  addScript(userUUID) {
    if (!SiftScienceSnippet.exists() && !SiftScienceSnippet.isImpersonate()) {
      this.userUUID = userUUID;
      this.assignInSiftVariable();
      const e = document.createElement('script');
      e.src = 'https://cdn.siftscience.com/s.js';
      document.body.appendChild(e);
    }
  }

  assignInSiftVariable() {
    const _sift = SiftScienceSnippet.getGlobalData();
    _sift.push(['_setAccount', SNIPPET_KEY]);
    _sift.push(['_setUserId', this.userUUID]);
    _sift.push(['_setSessionId', (new Date()).getTime()]);
  }

  track() {
    const _sift = SiftScienceSnippet.getGlobalData();
    if (this.userUUID && !SiftScienceSnippet.isImpersonate()) {
      _sift.push(['_trackPageview']);
    }
  }

  static exists() {
    return typeof window._sift === 'object' && window._sift.length > 0 && this.userUUID !== null;
  }

  static getGlobalData() {
    // eslint-disable-next-line
    return window._sift = window._sift || [];
  }

  static isImpersonate() {
    return !!localStorage.getItem('is_impersonate');
  }
}

const sift = new SiftScienceSnippet();
export default sift;
