import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import cloneDeep from 'lodash/cloneDeep';

import {
  LOG_TYPE,
  createCustomStore,
  logger,
  rehydrate,
  withScopes,
} from '@emobg/web-utils';
import { external } from '@emobg/web-api-client';
import { City } from '@Shared/legacy/City';

const SCOPES = {
  currentCity: 'currentCity',
  cityCollection: 'cityCollection',
};

export const GETTERS = {
  getCityCollection: 'getCityCollection',
  getCurrentCity: 'getCurrentCity',
  getCityCollectionByProfile: 'getCityCollectionByProfile',
};
export const MUTATIONS = {
  setCurrentCity: 'setCurrentCity',
  setCityCollection: 'setCityCollection',
};

export const ACTIONS = {
  fetchCities: 'fetchCities',
};

export const NAMESPACE = 'City';

export const CityStore = createCustomStore(() => withScopes(SCOPES,
  (
    {
      mutations: {
        [MUTATIONS.setCurrentCity](state, city) {
          state.currentCity = city;
        },
        [MUTATIONS.setCityCollection](state, cities) {
          state.cityCollection.data = isEmpty(state.cityCollection.data) ? cities : { ...state.cityCollection.data, ...cities };
        },
      },
      getters: {
        [GETTERS.getCityCollection]: state => userProfileUuid => {
          const cities = state.cityCollection.data;
          if (isEmpty(cities)) {
            return null;
          }
          return userProfileUuid ? cities[userProfileUuid] : cities;
        },
        [GETTERS.getCurrentCity]: state => state.currentCity,
        [GETTERS.getCityCollectionByProfile]: (_state, getters) => currentProfileUuid => {
          const cities = getters.getCityCollection(currentProfileUuid);
          // TODO Remove City object-values and add tests [https://europcarmobility.atlassian.net/browse/CF-480]
          return isArray(cities)
            ? map(cities, city => new City(city))
            : [];
        },
      },
      actions: {
        async [ACTIONS.fetchCities]({ commit }, { userProfileUuid }) {
          if (!userProfileUuid) {
            logger.message('userProfileUuid cannot be empty', LOG_TYPE.warning);
            return;
          }

          const cities = {};
          const storeName = `${NAMESPACE}_${userProfileUuid}`;
          const storedData = rehydrate.get(storeName);

          if (storedData) {
            commit(MUTATIONS.setCityCollection, cloneDeep(storedData[userProfileUuid]));
            return;
          }

          try {
            const citiesResponse = await external.fleetCities.getCities({ user_profile_uuid: userProfileUuid }, 'v2');
            set(cities, userProfileUuid, citiesResponse);
            commit(MUTATIONS.setCityCollection, cities);
            rehydrate.set(storeName, userProfileUuid, cities);
          } catch (error) {
            logger.message(`Error trying to get cities: ${error.message}`, LOG_TYPE.error);
          }
        },
      },
    }
  )));
