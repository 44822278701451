import get from 'lodash/get';
import { external } from '@emobg/web-api-client';
import {
  LOG_TYPE,
  STORE_REQUEST_STATUS,
  createCustomStore,
  logger,
} from '@emobg/web-utils';
import { isEndBetweenRules, setBookingRules } from '@/helpers/booking/bookingTimeHelpers';

export const NAMESPACE = 'BOOKING_RULES';

export const ACTIONS = {
  fetchBookingRules: 'FETCH_BOOKING_RULES',
};

export const GETTERS = {
  bookingRulesData: 'BOOKING_RULES_DATA',
  isBookingBetweenRules: 'IS_BOOKING_BETWEEN_RULES',
};

export const store = createCustomStore(({ runAsyncFlow }) => ({
  namespaced: false,
  getters: {
    [GETTERS.bookingRulesData](state) {
      const isLoaded = get(state, `STATUS.${STORE_REQUEST_STATUS.loaded}`, false);

      return isLoaded ? state.data : null;
    },
    [GETTERS.isBookingBetweenRules](state, getters) {
      return bookingTimer => {
        try {
          const data = getters[GETTERS.bookingRulesData];

          if (!data) {
            logger.message('Booking rules can not be checked: there is not booking rules data', LOG_TYPE.warning);
            return true;
          }

          const rules = {
            minDuration: data.minimumBookingDuration,
            maxDuration: data.maximumBookingDuration,
          };

          const newBookingTimer = setBookingRules(bookingTimer, rules);

          return isEndBetweenRules(newBookingTimer);
        } catch (error) {
          logger.message(`Booking rules can not be checked: ${error.message}`, LOG_TYPE.warning);
          return true;
        }
      };
    },
  },

  actions: {
    async [ACTIONS.fetchBookingRules]({ commit }, payload = {}) {
      const {
        bookingType,
        userProfileUuid,
        vehicleUuid,
        vehicleCategoryUuid,
        locationUuid,
      } = payload || {};

      const parameters = {
        bookingType,
        userProfileUuid,
        ...(vehicleUuid ? { vehicleUuid } : { vehicleCategoryUuid, locationUuid }),
      };

      await runAsyncFlow(commit, {
        request: external.booking.getBookingRules,
        params: [parameters],
      });
    },
  },
}));
