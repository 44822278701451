import toLower from 'lodash/toLower';
import findKey from 'lodash/findKey';
import includes from 'lodash/includes';

import { LOG_TYPE, REGIONS_LOCALES, logger } from '@emobg/web-utils';

import Cookie from 'js-cookie';

const LanguageService = {
  defaultLang: 'en',
  availablesLocales: ['en', 'es', 'ca', 'de', 'pt', 'da', 'it', 'fr', 'fr_ch', 'it_ch', 'de_ch'],
  trackedLocales: ['en', 'es', 'ca', 'de', 'pt', 'da', 'fr_ch', 'it_ch', 'de_ch'],
  dictionary: REGIONS_LOCALES,
  forceLanguage: null,

  getLanguage() {
    if (this.forceLanguage) {
      return this.forceLanguage;
    }

    const urlSearch = new URLSearchParams(window.location.search);
    const cookieValue = Cookie.get('lang');
    const urlValue = urlSearch.get('hl') || urlSearch.get('language');
    const navigatorValue = window.navigator.userLanguage || window.navigator.language;

    const value = cookieValue || urlValue || navigatorValue;

    const standardValue = toLower(value.replace('-', '_'));

    // get available locale from a region like 'ca_es', 'fr_ch', 'de_de', etc
    const availableLocaleByRegion
      = findKey(REGIONS_LOCALES, regionLocale => toLower(regionLocale) === standardValue);

    // standardValue is in availableLocales like 'es', 'de', 'it', etc
    const isAvailableLocale = includes(this.availablesLocales, standardValue);

    return isAvailableLocale ? standardValue : (availableLocaleByRegion || this.defaultLang);
  },

  setForceLanguage(locale) {
    if (!this.isLocaleValid(locale)) {
      logger.message(`force language receives an invalid locale: ${locale}`, LOG_TYPE.warning);
    }

    this.forceLanguage = this.isLocaleValid(locale) ? locale : this.defaultLang;
  },

  /**
   * Get de Language code to be passed to backend
   * @param locale
   */
  getLanguageCodeFromLocale(locale = '') {
    const l = Object.keys(this.dictionary).find(i => i === locale.toLowerCase());
    return this.dictionary[l];
  },

  isLocaleValid(locale = '') {
    return !!Object.keys(this.dictionary).find(i => i.toLowerCase() === locale.toLowerCase());
  },

  getLocaleFromLanguageCode(languageCode) {
    const locale = Object.keys(this.dictionary).filter(i => {
      const dictionaryCode = this.dictionary[i].replace('-', '_');
      const code = languageCode.replace('-', '_');

      return dictionaryCode.toLowerCase() === code.toLowerCase();
    })[0];

    return locale || this.getLanguage();
  },

  getLanguageCodeForHeader(defaultLocale = '') {
    return defaultLocale && this.forceLanguage
      ? this.getLanguageCodeFromLocale(this.getLanguage())
      : defaultLocale;
  },
};

export default LanguageService;
