import * as BookingRulesModule from './stores/BookingRules/BookingRulesModule';
import * as BookingInsuranceModule from './stores/BookingInsurance/BookingInsuranceModule';
import * as MyBookingsModule from './stores/MyBookings/MyBookingsStore';

export const NAMESPACE = 'BookingModule';

export const ACTIONS = {
  ...BookingRulesModule.ACTIONS,
};

export const GETTERS = {
  ...BookingRulesModule.GETTERS,
};

export const store = {
  namespaced: true,
  modules: {
    [BookingRulesModule.NAMESPACE]: BookingRulesModule.store,
    [BookingInsuranceModule.NAMESPACE]: BookingInsuranceModule.store,
    [MyBookingsModule.NAMESPACE]: MyBookingsModule.store,
  },
};
