import {
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';

import * as CityStore from '@/stores/City/CityStore';

export const {
  getCurrentCity,
  getCityCollection,
  getCityCollectionByProfile,
  fetchCities,
  setCurrentCity,
} = {
  ...mapGetters(CityStore.NAMESPACE, {
    getCurrentCity: CityStore.GETTERS.getCurrentCity,
    getCityCollection: CityStore.GETTERS.getCityCollection,
    getCityCollectionByProfile: CityStore.GETTERS.getCityCollectionByProfile,
  }),

  ...mapActions(CityStore.NAMESPACE, {
    fetchCities: CityStore.ACTIONS.fetchCities,
  }),

  ...mapMutations(CityStore.NAMESPACE, {
    setCurrentCity: CityStore.MUTATIONS.setCurrentCity,
    setCityCollection: CityStore.MUTATIONS.setCityCollection,
  }),
};
