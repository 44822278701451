import { external } from '@emobg/web-api-client';
import { createCustomStore, withScopes } from '@emobg/web-utils';

export const NAMESPACE = 'BookingInsurance';

export const SCOPES = {
  upgradeInsuranceInfo: 'upgradeInsuranceInfo',
};

export const ACTIONS = {
  getUpgradeInsuranceInfo: 'getUpgradeInsuranceInfo',
};

export const store = createCustomStore(({ runAsyncFlow }) => withScopes(SCOPES, (
  {
    actions: {
      async [ACTIONS.getUpgradeInsuranceInfo]({ commit }, { bookingUuid }) {
        await runAsyncFlow(commit, {
          request: external.booking.getUpgradeInsuranceInfo,
          params: [bookingUuid],
          scope: SCOPES.upgradeInsuranceInfo,
        });
      },
    },
  }
)));
